import React from 'react';
import './Program.css'; 
import program1 from '../assets/program1.jpg'; 
import program2 from '../assets/program2.jpg'; 
function Program() {
  return (
    <section className="program-section">
      <div className="program-block">
        <div className="program-content">
          <h2>Welcome To Webporters</h2>
          <p>
          A India-based company named Webpoters provides wonderful solutions and effective commercial outcomes to help your organization and brand establish a strong online presence. As an outcome IT solution, we have established ourselves. We intend to concentrate on updating the company’s general appearance concurrently.

          We are a recognized web design firm in Panchkula that helps you transform your company and improve your brand’s online reputation. Our ability to link a brand’s culture and future ideals through the design of e-commerce websites has set us apart from the competition. Experiences that our WordPress development firm generates inspire you for the rest of your life.
          </p>
        </div>
        <div className="program-image">
          <img src={program1} alt="Program" />
        </div>
      </div>
      <div className="program-block reverse">
        <div className="program-image">
          <img src={program2} alt="Program" />
        </div>
        <div className="program-content">
          <h2>Why Webporters?</h2>
          <p>
          The greatest services for site development, web design, and SEO are offered by Webporters. Each project was completed on schedule.
          </p>
          <button className="program-button">Learn More</button>
        </div>
      </div>
    </section>
  );
}

export default Program;
