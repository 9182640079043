import React, { useState, useEffect } from 'react';
import './Counter.css'; // Import your component-specific CSS file here

function Counter() {
  const [counters, setCounters] = useState({
    projects: 0,
    teamMembers: 0,
    experienceYears: 0
  });

  useEffect(() => {
    let projectsInterval;
    let teamMembersInterval;
    let experienceYearsInterval;

    const startCount = () => {
      projectsInterval = setInterval(() => {
        setCounters((prevCounters) => {
          if (prevCounters.projects >= 450) {
            clearInterval(projectsInterval);
            return prevCounters;
          }
          return { ...prevCounters, projects: prevCounters.projects + 1 };
        });
      }, 10);

      teamMembersInterval = setInterval(() => {
        setCounters((prevCounters) => {
          if (prevCounters.teamMembers >= 30) {
            clearInterval(teamMembersInterval);
            return prevCounters;
          }
          return { ...prevCounters, teamMembers: prevCounters.teamMembers + 1 };
        });
      }, 30);

      experienceYearsInterval = setInterval(() => {
        setCounters((prevCounters) => {
          if (prevCounters.experienceYears >= 10) {
            clearInterval(experienceYearsInterval);
            return prevCounters;
          }
          return { ...prevCounters, experienceYears: prevCounters.experienceYears + 1 };
        });
      }, 100);
    };

    startCount();

    // Clean up intervals on component unmount
    return () => {
      clearInterval(projectsInterval);
      clearInterval(teamMembersInterval);
      clearInterval(experienceYearsInterval);
    };
  }, []);

  return (
    <div className="counter-section">
      <div className="counter-item">
        <h3>Number of Projects</h3>
        <p>{counters.projects}+</p>
      </div>
      <div className="counter-item">
        <h3>Team Members</h3>
        <p>{counters.teamMembers}+</p>
      </div>
      <div className="counter-item">
        <h3>Years of Experience</h3>
        <p>{counters.experienceYears}+</p>
      </div>
    </div>
  );
}

export default Counter;
