import React, { useState } from 'react';
import './Contact.css'; 
function Contact() {
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    setTimeout(() => {
      
      setName('');
      setEmail('');
      setMessage('');
      setIsSubmitted(true);
      setSuccessMessage('Your message has been sent successfully!');
     
      setTimeout(() => {
        setIsSubmitted(false);
        setSuccessMessage('');
      }, 5000); 
    }, 1000); 
  };

  return (
    <div className="contact-container">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="5"
            required
          ></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
      {isSubmitted && (
        <p className="success-message">{successMessage}</p>
      )}
    </div>
  );
}

export default Contact;
