import React from 'react';
import './Navbar.css';
import logo from '../assets/logo-final.png'

const Navbar = () => {
  return (
    <nav className="navbar-web">
      <div className="navbar-container-web">
        <a href="/" className="navbar-logo-web">
          <img src={logo} alt="Logo" className="logo-image-web" />
        </a>
        <ul className="navbar-menu-web">
          <li className="navbar-item-web">
            <a href="/" className="navbar-links-web">Home</a>
          </li>
          <li className="navbar-item-web">
            <a href="/about" className="navbar-links-web">About</a>
          </li>
          <li className="navbar-item-web">
            <a href="/services" className="navbar-links-web">Services</a>
          </li>
          <li className="navbar-item-web">
            <a href="/contact" className="navbar-links-web">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
