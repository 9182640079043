import React from 'react';
import Navbar from './Components/Navbar';
import Hero from './Components/Hero';
import Services from './Components/Services';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Program from './Components/Program';
import Counter from './Components/Counter';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Hero/>
      <Program/>
      <Counter/>
      <Services/>
      <Contact/>
      <Footer/>
    </div>
  );
};

export default App;
