import React from 'react';
import './Hero.css';
import heroImage from '../assets/hero.jpg'; 

const Hero = () => {
  return (
    <section className="hero-web">
      <img src={heroImage} alt="Hero" className="hero-image-web" />
      <div className="hero-content-web">
        <h1 className="hero-title-web">Welcome to Our Website</h1>
        <p className="hero-subtitle-web">We build amazing web experiences</p>
        <a href="#services" className="hero-button-web">Learn More</a>
      </div>
    </section>
  );
};

export default Hero;
