import React from 'react';
import './Services.css'; // Import your component-specific CSS file here

function Services() {
  return (
    <section className="services-section">
      <div className="service">
        <h2>WordPress Development</h2>
        <p>Custom WordPress themes, plugins, and integrations tailored to your needs.</p>
      </div>
      <div className="service">
        <h2>WooCommerce Solutions</h2>
        <p>E-commerce solutions with WooCommerce, including store setup and customization.</p>
      </div>
      <div className="service">
        <h2>PHP Development</h2>
        <p>Server-side scripting and custom web applications using PHP.</p>
      </div>
    </section>
  );
}

export default Services;
