import React from 'react';
import './Footer.css'; // Import your component-specific CSS file here

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 Webporters. All rights reserved.</p>
        <p>Contact: info@webporters.com</p>
      </div>
    </footer>
  );
}

export default Footer;
